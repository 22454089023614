import React, { useEffect, useRef} from "react"
import { FormattedMessage } from "react-intl"

//importing components
import SEO from "../components/seo"
import BioItem from "../components/BioItem"
import ContactScreen from "../components/Contact"
import HomeScreen from "../components/Home"

import Layout from "../components/layout/en"  // here language in EN

//import styledComponents
import {
  StyledLink,
  LinkContainer,
  Padded,
  TopBar,
  WhoAmI,
  SectionTitle,
} from "../components/StyledHome"

import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger";

const WorkPage = (props) => {
  const item = useRef(null);

  useEffect(() => {
    if (typeof window !== "undefined") {
      gsap.registerPlugin(ScrollTrigger);
    }

    gsap.from(item.current, {
      autoAlpha: 0,
      scale: 0.7,
      scrollTrigger: {
        trigger: item.current,
        start: "top bottom",
        end: "top 150",
        markers: false,
        scrub: true,
        anticipatePin: 1,
      },
    });
  });

  return (
    <Layout location={props.location} >
      <SEO title={"Work"}/>
      <TopBar>
        <LinkContainer>
          <StyledLink to="/en/#bio">BIO</StyledLink>
          <StyledLink to="/en/#work">WORK</StyledLink>
          <StyledLink to="/en/#contact">CONTACT</StyledLink>
        </LinkContainer>
      </TopBar>


      <SectionTitle id="contact">CONTACT</SectionTitle>
      <ContactScreen />
    </Layout>
  )
}

export default WorkPage
